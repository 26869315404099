<script>
import {nextTick} from "vue"
import PopupGift from '@/components/popupGift/index.vue'
import axios from "axios"
export default {
	name: "Giftdetail",
	props:{
		prizeId:{
			type: String,
		}
	},
	data() {
		return {
			prizeData: {},
			isActivePopupGift: false,
			isSendLottery: false,
			isSendSucess: false,
			isLock: false,
			isEndExchange: false
		};
	},
	computed: {
		prizesList(){
			return this.$store.state.prizesList
		},
		allPoints(){
			return this.$store.state.allPoints
		},
		hostUrl(){
			return this.$store.state.hostUrl;
		},
		token(){
			return this.$store.state.token;
		},
		userNTInfo(){
			return this.$store.state.userNTInfo
		},
	},
	watch:{
		prizeId(){
			let vm=this
			if(vm.prizesList.length>=0){
				vm.getPrizeData()
			}
		},
		prizesList(){
			let vm=this
			vm.getPrizeData()
		},
		isSendLottery(){
			let vm=this
			vm.sendLottery()
		}
	},
	components:{
		PopupGift
	},
	mounted() {
		nextTick(()=>{
			let vm = this;
			let start = "2023-9-23 00:00";
			let now = new Date();
			let reg = new RegExp("-", "g");

			if (new Date(start.replace(reg, "/")) <= now) {
				vm.isEndExchange=true
			}
			vm.$emit("update:prizeId",vm.$route.params.id)
			if(vm.$route.params.id===vm.prizeId){
				vm.getPrizeData()
			}
		})
	},
	methods: {
		changeToBr(str){
			return str.replace(/\n/g, '<br>')
		},
		removeComma(str){
			return parseInt(str.replace(/,/g, ''))
		},
		sendLottery(){
			let vm = this;
			console.log("sendLottery")

			vm.$store.commit("loadingToggle",true)
			vm.isLock=true

			let data={
				prize_id: vm.prizeId,
			}

			axios.post(vm.hostUrl+"api/user/exchange",data,
				{
					headers: {
						"Authorization": `Bearer ${vm.token}`,
						"Accept": "application/json",
					},
				})
				.then((response)=>{
					console.log(response)
					if(response.data.success){
						vm.$store.dispatch("getUserNTInfo").then(()=> {
							setTimeout(() => {
								vm.isActivePopupGift=true
								vm.isLock=false
								vm.isSendSucess=true
								vm.$store.commit('loadingToggle',false)
							}, 2500);
						})
					}
				})
				.catch(function (error) {
					alert(error.response.data.error.message)
					vm.$store.commit('loadingToggle',false)
					vm.isLock=false
				});

			setTimeout(() => {
				vm.isSendSucess=true
				vm.isActivePopupGift=true
				vm.$store.commit("loadingToggle",false)
			}, 1000);

		},
		checkLottery(){
			let vm = this;
			// console.log("checkLottery")
			vm.isActivePopupGift=true
		},
		getPrizeData(){
			// console.log("getPrizeData")
			let vm=this
			vm.prizesList.forEach(element => {
				if(element.id===Number(vm.prizeId)){
					vm.prizeData=element
				}
			});
		}
	},
}
</script>
<template lang="pug" src="./template.pug"></template>
<style lang="sass" scoped src="./style.sass"></style>